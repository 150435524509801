/* Content */
main.speedie-layout-content {
  padding-left: @padding-lg;
  padding-right: @padding-lg;
  width: @layout-max-width + @padding-lg*2;
  margin: auto;
  padding-bottom: @padding-lg;
}
/* Card */
.speedie-card {
  box-shadow: @card-shadow;
  .speedie-card-head {
    border-bottom: none;
    .speedie-typography-secondary {
      font-size: @font-size-base;
      font-weight: normal;
    }
  }
  .speedie-card-actions {
    padding: @padding-md;
    margin-top: (@padding-sm - @padding-md);
    border-top: none;
    border-radius: 0 0 @card-radius @card-radius;
    background-color: #f9e132;
    cursor: none;
  }
}
/* Modal */
.speedie-modal {
  .speedie-modal-content {
    border-radius: @card-radius;
  }
  .speedie-modal-body {
    .speedie-form,
    .speedie-tabs {
      margin-top: @margin-md*2;
    }
    .speedie-tabs {
      .speedie-tabs-tab {
        color: #bfbfbf;
        &:hover {
          color: @primary-color;
        }
      }
      .speedie-tabs-nav {
        margin-bottom: 0;
      }
      .speedie-tabs-nav-list {
        width: 100%;
        .speedie-tabs-tab {
          width: -webkit-fill-available;
          .speedie-tabs-tab-btn {
            margin: auto;
          }
        }
      }
    }
    .speedie-form:not(:last-child) {
      margin-bottom: @margin-md;
    }
    .speedie-result {
      .speedie-result-icon {
        margin-bottom: @margin-sm;
      }
      .speedie-result-title {
        font-size: @font-size-lg;
        font-weight: 400;
        white-space: pre-line;
        line-height: 28px;
      }
      .speedie-result-subtitle {
        font-size: 36px;
        line-height: 50px;
        font-weight: 400;
        color: @primary-color;
        margin-top: @margin-xss;
      }
      .speedie-result-extra {
        margin-top: @margin-sm;
        line-height: 22px;
      }
    }
  }

  &.logo-modal {
    .speedie-modal-body {
      padding: 0px;
    }
  }
}
/* Form */
form.speedie-form {
  .speedie-form-item {
    margin-bottom: @margin-md;
    &:last-of-type {
      margin-bottom: 0;
    }
    .speedie-form-item-label {
      > label {
        height: 100%;
        background-color: @input-bg;
        border-top-left-radius: @input-height-base / 2;
        border-bottom-left-radius: @input-height-base / 2;
        padding-left: @padding-md;
        font-weight: 300;
      }
      + .speedie-form-item-control {
        .speedie-input,
        .speedie-select,
        .speedie-picker {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          text-align: end;
        }
      }
    }
    &.speedie-form-item-with-help {
      .speedie-form-item-label {
        > label {
          height: @input-height-base;
        }
      }
    }
    .speedie-input,
    .speedie-select,
    .speedie-picker {
      background-color: @input-bg;
      font-weight: 400;
      padding-left: @padding-md;
      padding-right: @padding-md;
      border-radius: @input-height-base / 2;
      height: @input-height-base;
      box-shadow: none;
      border: none;
      &.speedie-input-sm {
        border-radius: @input-height-sm / 2;
        height: @input-height-sm;
      }
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .speedie-input-suffix {
      background-color: @input-bg;
      border-top-right-radius: @input-height-base / 2;
      border-bottom-right-radius: @input-height-base / 2;
      padding-right: @padding-md;
      margin: 0;
      button {
        width: unset;
        padding: 0;
      }
    }
  }
  .speedie-input-affix-wrapper {
    height: @input-height-base;
    padding: 0;
    background-color: transparent !important;
    box-shadow: none;
    &.speedie-input-affix-wrapper-sm {
      height: @input-height-sm;
    }
  }
  //Checkbox
  .speedie-checkbox-group {
    > :not(:last-child) {
      margin-right: 24px;
    }
    .speedie-checkbox-wrapper {
      font-size: @font-size-lg;
      color: @label-color;
      height: @input-height-base;
      width: 165px;
      text-align: center;
      border-radius: @input-height-base / 2;
      background-color: @input-bg;
      span {
        line-height: @input-height-base;
      }
      .speedie-checkbox-inner {
        display: none;
      }
    }
    .speedie-checkbox-wrapper-checked {
      background-color: @btn-primary-bg;
      color: white;
      .speedie-checkbox-inner {
        display: block;
        border-radius: 50%;
        background-color: white;
        height: 24px;
        width: 24px;
        &::after {
          border: 2px solid @primary-color;
          border-top: 0;
          border-left: 0;
          width: 8px;
          height: 12px;
          margin-left: 1px;
        }
      }
    }
  }
  .speedie-form-item-has-error {
    input.speedie-input-borderless {
      box-shadow: none;
    }
    .speedie-form-item-explain-error {
      text-align: right;
      padding-right: @padding-md;
      margin-top: @margin-xss;
    }
  }
  // Radio
  .speedie-radio-group-outline {
    > :not(:last-child) {
      margin-right: 24px;
    }
    &.speedie-radio-group-small {
      .speedie-radio-wrapper {
        height: @input-height-base / 2;
        width: 80px;
        span {
          line-height: @input-height-base / 2;
        }
      }
    }
    .speedie-radio-wrapper {
      color: @label-color;
      height: @input-height-base;
      width: 165px;
      text-align: center;
      border-radius: @input-height-base / 2;
      background-color: #f5f5f5;
      span {
        line-height: @input-height-base;
      }
      .speedie-radio-inner {
        display: none;
      }
    }
    .speedie-radio-wrapper-checked {
      color: @btn-primary-color;
      background-color: @btn-primary-bg;
    }
    .speedie-radio-button-wrapper {
      width: 100%;
      padding: 0 @padding-md 0 @padding-md;
      line-height: @input-height-base;
      height: @input-height-base;
      border-radius: @input-height-base / 2;
      border: none;
      background-color: #f5f5f5;
      font-weight: 300;
      font-size: 16px;
      &:not(:first-child) {
        margin-top: @margin-sm;
        &::before {
          display: none;
        }
      }
      span {
        color: @label-color;
      }
      ::before {
        display: none;
      }
    }
    .speedie-radio-button-wrapper-checked {
      background-color: @btn-primary-bg;
      font-weight: 400;
      span {
        color: @btn-primary-color;
      }
    }
  }
  .speedie-select {
    .speedie-select-selector {
      border: none;
    }
    .speedie-select-arrow {
      padding-right: @padding-sm;
    }
    .speedie-select-dropdown {
      border-radius: 0px;
    }
  }
}
/* Typography */
.speedie-typography {
  display: block;
  white-space: pre-line;
  font-weight: 300;
  strong {
    font-weight: 400;
  }
}
/* Divider */
.speedie-divider-horizontal {
  margin: @margin-md 0;
  border-top: 1px solid @border-color-split;
}
/* List */
.speedie-list {
  .speedie-list-header {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
  .speedie-list-footer {
    border-top: 1px solid @border-color-split;
    margin-top: @margin-xs;
    padding-top: @padding-xs;
    padding-bottom: 0;
  }
}
/* Table */
.speedie-table-wrapper {
  tr {
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
    > th {
      height: 84px;
      font-size: 20px;
    }
    > td,
    > th {
      &:first-child {
        padding-left: @padding-md;
      }
      &:last-child {
        padding-right: @padding-md;
      }
      border-bottom: none;
    }
  }
  .speedie-table {
    border-radius: @table-border-radius-base;
  }
  .speedie-table-selection-column {
    z-index: 2;
    padding-left: @padding-md;
  }
  .speedie-table-placeholder {
    .speedie-table-cell {
      padding: @padding-lg;
      h3 {
        margin-top: @margin-lg;
        margin-bottom: 0;
        color: #bfbfbf;
      }
    }
  }
}
.speedie-table-filter-dropdown {
  .speedie-dropdown-menu.speedie-dropdown-menu-light.speedie-dropdown-menu-without-submenu.speedie-dropdown-menu-root.speedie-dropdown-menu-vertical {
    max-height: unset;
    .speedie-dropdown-menu-item {
      padding: @padding-xss / 2 @padding-sm;
    }
  }
  .speedie-btn.speedie-btn-sm {
    width: unset;
    height: unset;
    padding: @padding-xss / 2 @padding-sm;
  }
}
.speedie-pagination-options {
  .speedie-select {
    height: 32px;
    .speedie-select-selector {
      padding-left: 16px;
      padding-right: 24px;
      height: 32px;
      .speedie-select-selection-item {
        height: 32px;
        line-height: 30px;
      }
    }
  }
}
/* Image */
.speedie-image {
  padding: 8px;
  border: 1px solid @border-color-split;
}
/* Upload */
.speedie-upload.speedie-upload-select {
  width: 100%;
}
.speedie-upload-list-picture-container {
  margin-top: @margin-xss;
}
.speedie-upload-select-picture-card,
.speedie-upload-list-picture-card-container {
  width: 300px;
  height: 300px;
}
/* Space */
.speedie-space.speedie-space-vertical {
  width: 100%;
  display: block;
}
.speedie-picker {
  &.speedie-picker-borderless {
    background-color: #f5f5f5 !important;
  }
}
.speedie-picker-clear {
  display: contents;
}
.speedie-page-header-heading-title {
  font-weight: 400;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
